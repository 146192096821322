import React, { useContext, useCallback, MouseEvent, memo, FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import SortItems from '@root-gipro/shared/FilterHeader/components/sort-items'
import { lightPretty, prettyCost } from '@root-gipro/utils/helpers/common'
import { ISortItem } from '@root-gipro/shared/FilterHeader/components/sort-item.model'
import { TabsToggle } from '@root-gipro/shared/TabsComponents/TabsToggle'
import { TabsDropDown } from '@root-gipro/shared/FilterHeader/components/DropDown/TabsDropDown'
import { FilterSVG } from '@root-gipro/shared/SVGComponents/FilterSVG'
import { Reports } from '@root-gipro/modules/commonProjects/components/'
import { ModalContext } from '@root-gipro/context/modal-context'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { usePageLink } from '@root-gipro/shared/hooks/usePageLink'
import { history, Routes } from '@root-gipro/router'
import { makeStyles } from '@material-ui/core/styles'
import '@root-gipro/shared/FilterHeader/styles/FilterHeader.scss'
import { ProjectType } from '@root-gipro/modules/favorites/models/enums/projects.enum'
import ButtonDelete from '@root-gipro/modules/favorites/components/ButtonDelete'
import { ButtonStyle, ModalFavorites, Title, WarningBlockDiv, WrapperButton } from './styles'
import {
	deleteFavoritesProjects,
	setFavoritesProjectsCount,
	setFavoritesProjectsSum,
	setFavoritesPurchasesCount,
	setFavoritesPurchasesSum,
} from '@root-gipro/modules/favorites/store/actions'

const useStyles = makeStyles({
	projectsHeadSortText: {
		color: 'rgba(0, 0, 0, 0.56)',
		fontSize: '16px',
		marginRight: 8,
	},
})

export interface IFilterHead {
	editToggleType(toggleType: 'offer' | 'plan' | 'notice' | ProjectType.PROJECTS | ProjectType.PURCHASES): void
	toggleType: 'offer' | 'plan' | 'notice' | ProjectType.PROJECTS | ProjectType.PURCHASES
	projectsCount: number
	offerSum?: number
	planSum: number
	sort?(name: string, sortName: string, order?: boolean): void
	sortList: ISortItem[]
	types: types[]
	isShowFilter(): void
	handleFavorites?(event: MouseEvent<HTMLDivElement>): void
	isFavorite: boolean
	favoritesButtonDelete?: React.ReactNode | any
}
export type types = {
	title: string
	value: string
}

const FilterHeaderContainer: FC<IFilterHead> = memo(
	({
		toggleType,
		editToggleType,
		projectsCount,
		offerSum,
		planSum,
		sort,
		sortList,
		types,
		isShowFilter,
		handleFavorites,
		isFavorite,
	}) => {
		const dispatch = useDispatch()
		const [isModal, setIsModal] = useState(false)
		const { isFilterOpen } = useSelector((state: IState) => state.maket)
		const { toggleType: toggleTypeFavorites, favoritesList } = useSelector((state: IState) => state.favorites)
		const isActualFilter = useSelector((state: IState) => state.filter.isActualFilter)
		const { setAnchorEl, anchorEl } = useContext(ModalContext)
		const { currentPath } = usePageLink()
		const classes = useStyles()

		const handleClick = useCallback(
			(event: MouseEvent<HTMLElement>) => {
				setAnchorEl!(anchorEl ? null! : event.currentTarget)
			},
			[anchorEl]
		)
		// console.log(purchases.filter((item)=>item.winner))
		// console.log(purchases)

		const onCancel = () => {
			setIsModal(false)
		}
		const onDeleteFavorites = () => {
			let listOfDelete = favoritesList.map((item: { id: string }) => item.id)
			dispatch(deleteFavoritesProjects(listOfDelete, toggleTypeFavorites))
			dispatch(setFavoritesProjectsSum(0, 0))
			dispatch(setFavoritesProjectsCount(0))

			dispatch(setFavoritesPurchasesSum(0))
			dispatch(setFavoritesPurchasesCount(0))

			setIsModal(false)
		}

		return (
			<>
				<div className='projects__head'>
					<div className='projects__head__left'>
						{history.location.pathname !== '/favorites' && (
							<SecondaryBtn
								onClick={isShowFilter}
								className={`projects__head__filter-btn  ${isFilterOpen && 'active'} `}
							>
								<FilterSVG />
								Фильтр
							</SecondaryBtn>
						)}
						{sort && <span className={classes.projectsHeadSortText}>Сортировка по:</span>}
						{sort && <SortItems sortItems={sort} sortList={sortList} />}
						{Routes.COMMON_PROJECTS === currentPath && <Reports />}
					</div>

					<div className='projects__head__right'>
						{(history.location.pathname === '/favorites-common-projects' ||
							history.location.pathname === '/favorites-purchases') &&
							favoritesList?.length > 0 && <ButtonDelete onClick={() => setIsModal(true)} />}
						<div className='projects__head-info ml-8'>
							{/* {handleFavorites && history.location.pathname !== '/favorites' && (
							<div
								style={{ marginRight: '8px', cursor: 'pointer' }}
								data-favorites
								onClick={(event: MouseEvent<HTMLDivElement>) => handleFavorites(event)}
							>
								<StarSVG chosen={isFavorite} />
							</div>
						)} */}

							{toggleType !== 'plan' && (
								<div className='desktop-tabs'>
									<TabsToggle
										types={types}
										editToggleType={(
											value: 'offer' | 'plan' | 'notice' | ProjectType.PROJECTS | ProjectType.PURCHASES
										) => editToggleType(value)}
										toggleType={toggleType}
									/>
								</div>
							)}

							<div className='mobile-tabs' data-popup='korr' onClick={handleClick}>
								<SecondaryBtn className='min-w-32'>К</SecondaryBtn>

								{anchorEl?.dataset.popup === 'korr' && (
									<TabsDropDown
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl!}
										types={types}
										editToggleType={editToggleType}
										toggleType={toggleType}
									/>
								)}
							</div>
							{isActualFilter === 1 ? (
								<div className='projects__head-info-fix-width'>
									<div className='projects__head__total-count'>
										<span>Всего: </span>
										{lightPretty(String(projectsCount))}
									</div>
									<div className='projects__head-sum'>
										{prettyCost(toggleType === 'plan' ? planSum : offerSum || planSum)} млн.р
									</div>
								</div>
							) : null}
						</div>
					</div>
					{isModal && (
						<ModalFavorites
							onClick={e => {
								e.preventDefault()
								e.stopPropagation()
								setIsModal(false)
							}}
						>
							<WarningBlockDiv
								onClick={e => {
									e.stopPropagation()
								}}
							>
								<Title>{`Подтвердите, действительно ли вы хотите удалить все ${
									toggleType === 'PROJECTS' ? 'Проекты' : 'Закупки'
								} из избранного?`}</Title>
								<Title>{`Все ${toggleType === 'PROJECTS' ? 'Проекты' : 'Закупки'} будут удалены.`}</Title>
								<WrapperButton>
									<ButtonStyle onClick={onDeleteFavorites} title='Подтверждаю'>
										Подтверждаю
									</ButtonStyle>
									<ButtonStyle type='cancel' onClick={onCancel}>
										Отмена
									</ButtonStyle>
								</WrapperButton>
							</WarningBlockDiv>
						</ModalFavorites>
					)}
				</div>
			</>
		)
	}
)

export default FilterHeaderContainer
