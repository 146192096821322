import styled from 'styled-components'
import '@root-gipro/styles/config/variables.scss'

export const ButtonFavoritesDelete = styled.div`
	display: flex;
	cursor: pointer;
	height: 32px;
	padding: 0 12px;
	align-items: center;
	border-radius: 4px;
	color: #fff !important;
	border: 1px solid #3f4f5e !important;
	background-color: #3f4f5e !important;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	font-family: 'Roboto', sans-serif;
`
