import { FormControl, MenuItem, Select } from '@material-ui/core/'
import { bodyParams } from '@root-gipro/modules/commonProjects/models/const/invest-table-body.const'
import { parametersDetailedData } from '@root-gipro/modules/commonProjects/models/const/parameters-detail-data.const'
import { tabsParams, types } from '@root-gipro/modules/commonProjects/models/const/tabs-params.const'
import { Notices, Plans } from '@root-gipro/modules/commonProjects/models/interfaces/common-projects.model'
import { IDeatailed } from '@root-gipro/modules/commonProjects/models/interfaces/parameters.props'
import { gridTemplateColumns, theadData } from '@root-gipro/modules/purchases/models/consts/invest-table-head.const'
import { ArrowDownSVG } from '@root-gipro/shared/SVGComponents/ArrowDownSVG'
import TableInvest from '@root-gipro/shared/Table/container/TableInvest'
import { TabsToggle } from '@root-gipro/shared/TabsComponents/TabsToggle'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonStyle, InformationBlockDiv, OfferInfo, PlanInfo } from './styled'
export const Detailed: React.FC<IDeatailed> = ({ project, toggleType, editToggleType }) => {
	const [isFile, setIsFile] = useState<boolean>(false)
	const [detailed, setDetailed] = useState<string>(tabsParams[0].parameter)
	const [tableProject, setProject] = useState<Notices[] | Plans[] | null>(null)

	const handleChange = useCallback(
		(event: React.ChangeEvent<{ value: unknown }>) => {
			setDetailed(event.target.value as string)
		},
		[detailed]
	)
	const handleClose = useCallback(() => {
		setProject(null)
	}, [tableProject])

	const minusYear = (date: string | null) => {
		if (date) {
			const year = Number(date.split('.')[2]) - 1
			let newDate = date.split('.')
			newDate[2] = year.toString()

			return newDate.join('.')
		}
	}
	const downloadFile = () => {
		window.open(`https://portal.gipro.su/files/passports/${project.code}.xlsx.zip`)
	}

	const showModalInvestmentProjects: (data: Notices[] | Plans[]) => void = useCallback(
		(data: Notices[] | Plans[]) => () => {
			setProject(data)
		},
		[tableProject]
	)

	const theadParams = useMemo(() => {
		return {
			gridTemplateColumns,
			theadData,
		}
	}, [])

	useEffect(() => {
		fetch(`https://portal.gipro.su/files/passports/${project.code}.xlsx.zip`)
			.then(req => req.blob())
			.then(data => data.type.includes('zip'))
			.then(bool => setIsFile(bool))
			.catch(error => console.warn(error))
	}, [])

	return (
		<div className='container'>
			<div className='parameters__detailed'>
				<div className='parameters__detailed-head'>
					<div className='parameters__detailed-select desktop-screen'>
						{tabsParams.map(val => (
							<div
								key={val.parameter}
								onClick={() => setDetailed(val.parameter)}
								className={`parameters__detailed-info ${detailed === val.parameter ? 'active' : ''}`}
							>
								{val.name}
							</div>
						))}
					</div>
					<FormControl className='mobile-screen'>
						<Select
							IconComponent={ArrowDownSVG}
							value={detailed}
							displayEmpty
							onChange={handleChange}
							className='parameters__detailed-select'
						>
							{tabsParams.map(val => (
								<MenuItem
									key={val.parameter}
									className={`parameters__detailed-info ${detailed === val.parameter ? 'active' : ''}`}
									value={val.parameter}
								>
									{val.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<div className='parameters__toggle'>
						<TabsToggle
							types={types}
							editToggleType={(value: string) => editToggleType(value)}
							toggleType={toggleType}
						/>
					</div>
					<InformationBlockDiv>
						<PlanInfo>План</PlanInfo>
						<OfferInfo>Корректировка</OfferInfo>
					</InformationBlockDiv>
				</div>
				<div className='parameters__detailed-data'>
					{showModalInvestmentProjects &&
						parametersDetailedData(showModalInvestmentProjects, toggleType, project, minusYear)
							.filter(item => item.tabsParams.includes(detailed))
							.map((elem, index) => {
								if (!!elem.title()) {
									return elem.iteration ? (
										elem.iteration.map((year: string) => (
											<div key={year} className='parameters__detailed-item '>
												<div
													className={`title ${elem.classesTitle}`}
													onClick={() => elem.clickTitle && elem.clickTitle()}
												>
													{elem.title(year)}
												</div>
												<div>{elem.value(year)}</div>
											</div>
										))
									) : (
										<div key={index} className='parameters__detailed-item '>
											<div
												className={`title ${elem.classesTitle}`}
												onClick={() => elem.clickTitle && elem.clickTitle()}
											>
												{elem.title()}
											</div>
											{elem.value()}
											{/* <div className='value'></div> */}
										</div>
									)
								}
							})}
				</div>
			</div>
			<TableInvest
				investProjectDetailsAnchorEl={Boolean(tableProject)}
				onClose={handleClose}
				projects={tableProject}
				theadParams={theadParams}
				bodyParams={bodyParams}
			/>
			{isFile && <ButtonStyle onClick={downloadFile}>Скачать паспорт проекта</ButtonStyle>}
		</div>
	)
}
