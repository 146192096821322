import { Slider } from '@material-ui/core'
import { FilterItemRangePriceProps } from '@root-gipro/shared/Filter/models/filter-item-range-price.props'
import { Input } from '@root-gipro/shared/FormElements'
import React, { ChangeEvent, useEffect, useState } from 'react'

const FilterItemRangeNumbers: React.FC<FilterItemRangePriceProps> = ({
	setStart,
	setEnd,
	start,
	end,
	name,
	fieldNameStart,
	fieldNameEnd,
	minMax = { min: 0, max: 9000000000 },
	startName = 'с',
	endName = 'по',
	disabledAll,
	withCheckbox,
	convertValue,
}) => {
	const [minValueRange, setMinValueRange] = useState(minMax.min)
	const [maxValueRange, setMaxValueRange] = useState(minMax.max)

	const [startPrice, setStartPrice] = useState<string | number>('')
	const [endPrice, setEndPrice] = useState<string | number>('')

	const [value, setValue] = React.useState<number[]>([minValueRange, maxValueRange])

	useEffect(() => {
		if (fieldNameStart !== 'unc.count.min') {
			setMinValueRange(
				minMax[fieldNameStart.replace('.min', '')]
					? Math.round(Number(minMax[fieldNameStart.replace('.min', '')].min))
					: 5
			)
			setMaxValueRange(
				minMax[fieldNameEnd.replace('.max', '')]
					? Math.round(Number(minMax[fieldNameEnd.replace('.max', '')].max))
					: 9000000000
			)
		}
	}, [minMax, minValueRange, maxValueRange])

	useEffect(() => {
		if (!end && !start && fieldNameStart === 'unc.count.min') {
			setStartPrice(Number(minValueRange))
			setEndPrice(Number(maxValueRange))
		} else if (!end && !start) {
			setStartPrice(Math.round(Number(minValueRange) / 10 ** 6).toLocaleString())
			setEndPrice(Math.round(Number(maxValueRange) / 10 ** 6).toLocaleString())
			setValue([minValueRange, maxValueRange])
		}

		if (start && end) {
			setValue([Number(start), Number(end)])
		} else if (start && !end) {
			setValue([Number(start), maxValueRange])
		} else if (!start && end) {
			setStart(minValueRange)
			setValue([minValueRange, Number(end)])
		}
	}, [start, end, minValueRange, maxValueRange])

	useEffect(() => {
		if (fieldNameStart !== 'unc.count.min') {
			setStartPrice(Math.round(Number(minValueRange) / 10 ** 6).toLocaleString())
			setEndPrice(Math.round(Number(end) / 10 ** 6).toLocaleString())
		} else {
			setStartPrice(Number(minValueRange))
			setEndPrice(Number(maxValueRange))
		}
	}, [])

	const handlerChangeField = (e: ChangeEvent<HTMLInputElement>, type: string) => {
		const value = e.currentTarget.value
		const formattedValue = Number(value.replace(/\D/g, '')).toLocaleString()
		if (type === 'start') {
			if (fieldNameStart === 'unc.count.min') {
				setStartPrice(value)
			} else {
				setStartPrice(formattedValue)
			}
		} else if (type === 'end') {
			if (fieldNameEnd === 'unc.count.max') {
				setEndPrice(value)
			} else {
				setEndPrice(formattedValue)
			}
		}
	}

	const handlerChangeFieldCommitted = (
		e: ChangeEvent<HTMLInputElement>,
		setFn: (value: number) => void,
		type: string
	) => {
		const value = e.currentTarget.value
		const intValue = Number(value.replace(/\D/g, ''))

		if (type === 'end' && intValue * 10 ** 6 >= maxValueRange) {
			//@ts-ignore
			setFn(null)
			if (fieldNameStart === 'unc.count.min') {
				setEndPrice(Math.round(Number(maxValueRange)))
			} else {
				setEndPrice(Math.round(Number(maxValueRange) / 10 ** 6).toLocaleString())
			}
		} else {
			setFn(intValue * 10 ** 6)
		}
	}

	const handleChange = (event: any, newValue: any) => {
		const formattedStartValue = Math.round(newValue[0] / 10 ** 6).toLocaleString()
		const formattedEndValue = Math.round(newValue[1] / 10 ** 6).toLocaleString()

		setStartPrice(formattedStartValue)
		setEndPrice(formattedEndValue)
		setValue(newValue as number[])
	}
	const handleChangeCommitted = (event: any, newValue: any) => {
		setStart(newValue[0])
		setEnd(newValue[1])
	}

	const resetFields = () => {
		if (start || end) {
			//@ts-ignore
			setStart(null)
			//@ts-ignore
			setEnd(null)
		}
	}

	return (
		<div className='range-price'>
			<div className='range-price__head'>
				<h5 className='range-price__head-title'>{name === 'Количество' ? 'Количество' : 'Стоимость, млн.₽'}</h5>
				<span className='range-price__head-title link link-grey' onClick={resetFields}>
					Сбросить
				</span>
			</div>
			<div className='range-price__duration'>
				<Input
					inputValue={startPrice}
					disabled={disabledAll || withCheckbox?.checked}
					handleInput={(e: ChangeEvent<HTMLInputElement>) => handlerChangeField(e, 'start')}
					handleBlur={(e: ChangeEvent<HTMLInputElement>) => handlerChangeFieldCommitted(e, setStart, 'start')}
					classes='part-width'
				/>
				<div>—</div>
				<Input
					inputValue={endPrice}
					disabled={disabledAll || withCheckbox?.checked}
					handleInput={(e: ChangeEvent<HTMLInputElement>) => handlerChangeField(e, 'end')}
					handleBlur={(e: ChangeEvent<HTMLInputElement>) => handlerChangeFieldCommitted(e, setEnd, 'end')}
					classes='part-width'
				/>
			</div>
			{fieldNameStart !== 'unc.count.min' ? (
				<Slider
					value={value}
					onChange={handleChange}
					onChangeCommitted={handleChangeCommitted}
					min={minValueRange}
					max={maxValueRange}
				/>
			) : null}
		</div>
	)
}

export default FilterItemRangeNumbers
