import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSpecialUnc, addUnc, setUncCellsFullInfo } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import { tbodyArr } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'
import {
	ISelectTableProps,
	uncDataFilter,
} from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { SelectTableThead } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTableThead'
import { SelectTableTbody } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTableTbody'
import { SelectTableHeader } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTableHeader'
import { selectTableElem } from '@root-gipro/modules/userProjects/store/actions'
import { Button } from '@material-ui/core'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { setFilterConfigureModalState, setFilterConfigures, setFilterUncsModalState } from '@root-gipro/modules/filter/store/actions'
import ConfigureFilter from '@root-gipro/modules/filter/components/ConfigureFilter'

export const SelectTable: React.FC<ISelectTableProps> = ({ setShowDirections }) => {
	const [checkedTable, setChecked] = useState('1')
	const [showInter, setShowInter] = useState(false)
	const [isAlreadyAded, setIsAlreadyAded] = useState(false)

	const { selectedElem, uncTableData, interpolation, originalObj } = useSelector((state: IState) => state.userProjects)
	const configures = useSelector((state: IState) => state.filter.configures)
	const {isFilterConfigureModalState} = useSelector((state: IState) => state.filter)	
	const [idUncForFolter, setIdUncForFilter] = useState<any>()
	const isFilterUncsModalState = useSelector((state: IState) => state.filter.isFilterUncsModalState)
	const uncCodes = useSelector((state: IState) => state.filter.uncCodes)

	const [selectedCells, setSelectedCells] = useState<{ x: number; y: number; name?: string; id?: number }[]>([])
	const [dataUncForFolter, setDataUncForFilter] = useState<uncDataFilter>({ x: '', id: '' })

	const tableNums: string[] = tbodyArr.filter(item => item.sitePreparation).map(item => String(item.tableNum))
	const dispatch = useDispatch()
	const handlerAddUnc = (uncCellId: string, interpolation: number, active: number) => {
		if (uncCellId && !interpolation && active) {
			dispatch(addUnc(checkedTable, uncCellId, 0, true))
		}
	}
	const confirmHandler = () => {
		let clone = Object.assign({}, interpolation)
		Object.keys(interpolation).forEach(key => {
			if (clone[key] === 'deleted') {
				delete clone[key]
			}
		})

		selectedElem && dispatch(createSpecialUnc(selectedElem.id, originalObj.regionIds[0], clone, checkedTable))
	}
	useEffect(()=>{
		const checkUnc = uncCodes.find((item)=>item.id === dataUncForFolter.id || item.id === configures.id )

		setIsAlreadyAded(!!checkUnc)

	},[dataUncForFolter,configures])
	const readyHandler = () => {
		if(!isAlreadyAded){
			if (dataUncForFolter.id && selectedCells[0]?.name) {
					dispatch(setUncCellsFullInfo(dataUncForFolter.id))
					setDataUncForFilter({ x: '', id: '' })
	
			}
			else if ( configures.id && selectedCells[0]?.name){
				dispatch(setUncCellsFullInfo(configures.id))
				dispatch(setFilterConfigures({id: "",x: ""}))

	
			}
		}
	}

	const cancelHandler = () => {
		dispatch(selectTableElem(null))
		setSelectedCells([])
		dispatch(setFilterUncsModalState(false))
	}

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				cancelHandler()
			}
		}

		if (isFilterUncsModalState) {
			document.addEventListener('keydown', handleKeyDown)
		} else {
			document.removeEventListener('keydown', handleKeyDown)
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [isFilterUncsModalState])
	const handleOpenConfigure = ()=>{
		dispatch(setFilterConfigureModalState(true))
	}
	return (
		<div
			className={`main_table ${selectedElem ? 'main_table__show' : ''} ${
				isFilterUncsModalState ? 'main_table__filter_side' : ''
			}`}
		>
			<div className='pannel_header'>
				<div className='pannel__nav'>
					<Button
						onClick={() => {
							dispatch(selectTableElem(null))
							setDataUncForFilter({ x: '', id: '' })
							if (isFilterUncsModalState) {
								setSelectedCells([])
							}
						}}
						className='pannel__nav-btn'
					>
						Назад
					</Button>
				</div>
				{selectedElem && (
					<>
						<div className='pannel_header-wrapper'>
							<div className='title'>
								<div>
									<span className='table_num'>{`Таблица ${selectedElem.code}. `}</span>
									{selectedElem.name}
								</div>
							</div>
							<div>
								<div className='subtitle'>{`Измеритель: ${selectedElem.unit}`}</div>
								<div onClick={() => setShowDirections(true)} className='note_btn'>
									Указания
								</div>
							</div>
						</div>
						{!isFilterUncsModalState ? (
							<SelectTableHeader
								setChecked={setChecked}
								checkedTable={checkedTable}
								tableNums={tableNums}
								selectedElem={selectedElem}
								uncTableData={uncTableData}
								setShowInter={setShowInter}
								showInter={showInter}
								createSpecialUnc={confirmHandler}
							/>
						) : (
							''
						)}
					</>
				)}
			</div>
			<div className={`table_wrapper ${isFilterUncsModalState ? 'table_wrapper__filter_sidebar' : ''} `}>
				<table className='add_table'>
					<SelectTableThead uncTableData={uncTableData} dataUncForFolter={dataUncForFolter} filterSide={isFilterUncsModalState} />
					<SelectTableTbody
						selectedCells={selectedCells}
						setSelectedCells={setSelectedCells}
						handlerAddUnc={handlerAddUnc}
						uncTableData={uncTableData}
						filterSide={isFilterUncsModalState}
						setDataUncForFilter={setDataUncForFilter}
					/>
				</table>
				{isFilterUncsModalState && (
					<>
					{uncTableData?.body&&
					<div className='action_btns filter_side_btns mt-8 select_configure'>
					<SecondaryBtn variant='contained' onClick={handleOpenConfigure} className='fullWidth'>
							Задать
						</SecondaryBtn>
					</div>

					}
					<div className='action_btns filter_side_btns mt-8 select_filter'>
						{selectedCells.length ? (
							<PrimaryBtn variant='contained' disabled={isAlreadyAded} onClick={readyHandler} className='fullWidth'>
								Применить
							</PrimaryBtn>
						) : (
							''
						)}
						<SecondaryBtn variant='contained' onClick={cancelHandler} className='fullWidth'>
							Закрыть
						</SecondaryBtn>
					</div>
					</>
				)}
			</div>
			{isFilterConfigureModalState&&
			<ConfigureFilter setSelectedCells={setSelectedCells} uncTableData={uncTableData}/>
			}
		</div>
	)
}
