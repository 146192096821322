import React, { useEffect, useContext, useState, useCallback, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalContext } from '@root-gipro/context/modal-context'
import { list, subList } from '@root-gipro/modules/commonProjects/models/const/reports.const'
import { ReportsSVG } from '@root-gipro/shared/SVGComponents/ReportsSVG'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { ReportsDropDown } from '@root-gipro/modules/commonProjects/components/Reports/DropDown/ReportsDropDown'
import { reportRequest } from '@root-gipro/modules/commonProjects/store/actions'
import styles from '@root-gipro/modules/commonProjects/styles/Reports.module.scss'
import cn from 'classnames'
import { IState } from '@root-gipro/store/interfaces'
import { Alert } from '@material-ui/lab'
import { Link } from 'react-router-dom'

export type DownloadData = {
	name: string
	order: boolean
	sortName: string
}

const Reports = () => {
	const { roleId } = useSelector((state: IState) => state.auth.user)
	const { setAnchorEl, anchorEl, elem, setElem } = useContext(ModalContext)
	const [open, setOpen] = useState(false)
	const [placement, setPlacement] = useState<string>()
	const dispatch = useDispatch()
	const role = Number(window.localStorage.getItem('roleId'))
	const [showAlert, setShowAlert] = useState(false)

	const handleClick = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			setAnchorEl!(anchorEl ? null! : event.currentTarget)
			setElem!(null!)
			setOpen(false)
			setPlacement(null!)
		},
		[anchorEl]
	)
	const handleClickForPopper = (event: React.MouseEvent<HTMLDivElement>, newPlacement: string | DownloadData) => {
		if (typeof newPlacement !== 'string') {
			const params = { name: newPlacement.name, sortName: newPlacement.sortName, order: newPlacement.order }
			if (role && role === 999) {
				setShowAlert(true)
			} else {
				dispatch(reportRequest(params))
			}
			return true
		}

		setElem!(event.currentTarget)
		setOpen(prev => placement !== newPlacement || !prev)!
		setPlacement(newPlacement)!
	}
	const handleClickAway = () => {
		setAnchorEl!(null!)
		setElem!(null!)
		setOpen(false)
		setPlacement(null!)
	}

	useEffect(() => {
		setAnchorEl!(null!)
		setElem!(null!)
		setOpen(false)
		setPlacement(null!)
	}, [])

	return (
		<div>
			{showAlert === true && (
				<Alert severity='warning' action={<Link to='/tariffs'>Подключить</Link>}>
					Отчет доступен только при подключении тарифа
				</Alert>
			)}
			{/* <SecondaryBtn
				data-popup='reports'
				onClick={handleClick}
				className={cn('min-w-32', {
					[styles.activeBtn]: anchorEl?.dataset.popup === 'reports',
				})}
			>
				<div
					className={cn(styles.svg, {
						[styles.activeSvg]: anchorEl?.dataset.popup === 'reports',
					})}
				>
					<ReportsSVG />
				</div>
				<div className={styles.shrtTxt}>Отчеты</div>
			</SecondaryBtn> */}
			{anchorEl?.dataset.popup === 'reports' && (
				<ReportsDropDown
					handler={handleClickForPopper}
					open={open}
					elem={elem}
					subList={subList}
					list={list}
					anchorEl={anchorEl}
					handleClickAway={handleClickAway}
					roleId={roleId}
				/>
			)}
		</div>
	)
}
export default Reports
