import React, { useCallback } from 'react'
import { ISelectTableTheadProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'

export const SelectTableThead: React.FC<ISelectTableTheadProps> = ({ uncTableData, dataUncForFolter, filterSide }) => {
	const selectCellHeader: any = useCallback(
		(item: any, elem: any, num = 1) => {
			const lastElement: any =
				uncTableData?.header &&
				uncTableData?.header.length > 1 &&
				uncTableData?.header[uncTableData?.header.length - num]
			
			if (dataUncForFolter?.x && lastElement.length === elem.length) {
				const hasMatchingX: any = Object.values(lastElement).filter((td: any) => td?.x === dataUncForFolter.x)

				if (item?.x === hasMatchingX[0]?.x && (item.col === undefined || item.col < 3)) {
					return 'selected-cell'
				} else if (item?.x !== hasMatchingX[0]?.x && num === 1) {
					if ((item?.x === hasMatchingX[0]?.x - 1 || item?.x === hasMatchingX[0]?.x + 1) && item.col === 2) {
						return 'selected-cell'
					}
				} else if (item?.x !== hasMatchingX[0]?.x) {
					return selectCellHeader(item?.x, elem, num + 1)
				}
			}

			return ''
		},
		[uncTableData, dataUncForFolter]
	)

	return (
		<thead>
			{uncTableData &&
				uncTableData.header &&
				uncTableData.header?.map((elem: any, ind: number) => {
					const isLastOrPenultimate = ind === uncTableData.header.length - 1 || ind === uncTableData.header.length - 2
					return (
						<tr key={ind}>
							{Object.values(elem).map((td: any) => {
								if (td.class === 'invis') return null

								return (
									<th
										className={
											filterSide && dataUncForFolter?.x && isLastOrPenultimate && selectCellHeader(td, elem)
										}
										key={`${td.x}_${td.y}`}
										colSpan={td.col ? td.col : null}
										rowSpan={td.row ? td.row : null}
									>
										{td.name}
									</th>
								)
							})}
						</tr>
					)
				})}
		</thead>
	)
}
