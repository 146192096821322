import React, { useEffect } from 'react'
import './PresetFilters.scss'
import { deletePresetFilters, getPresetFilters, setOpenPresetFilterModal, setPresetFilters } from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import { Button, Chip, makeStyles } from '@material-ui/core'

const useStyleChip = makeStyles(theme => ({
	root: {
		maxWidth: 235,
	},
}))

const useStyleButton = makeStyles(theme => ({
	root: {
		textTransform: 'none',
	},
}))

interface PresetFiltersProps {}

const PresetFilters: React.FC<PresetFiltersProps> = () => {
	const classes = useStyleChip()
	const classesButton = useStyleButton()
	const dispatch = useDispatch()
	const { dataPresetFilter } = useSelector((state: IState) => state.filter)

	useEffect(() => {
		dispatch(getPresetFilters())
	}, [])

	const deleteChip = (id: string) => {
		dispatch(deletePresetFilters(id))
	}

	const setFilterPreset = async (filters: any) => {
		dispatch(setPresetFilters('common-projects', filters))
	}

	return (
		<div className='filter_presets'>
			<div className='filter_presets__items'>
				{dataPresetFilter.map(item => {
					return (
						<Chip
							classes={classes}
							key={item.id}
							label={item.name}
							onClick={() => setFilterPreset(item.filters)}
							onDelete={() => deleteChip(item.id)}
						/>
					)
				})}
			</div>
			<div className='filter_presets__save_modal'>
				<Button
					classes={classesButton}
					variant='text'
					onClick={() => {
						dispatch(setOpenPresetFilterModal(true, 'edit'))
					}}
				>
					Редактировать
				</Button>
				<Button
					classes={classesButton}
					variant='text'
					onClick={() => {
						dispatch(setOpenPresetFilterModal(true, 'save'))
					}}
				>
					Сохранить
				</Button>
			</div>
		</div>
	)
}

export default PresetFilters
