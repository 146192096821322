import { ReactText } from 'react'
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { fetchHeaders, fetchData, userProjectsApi } from '@root-gipro/store/api'
import { createProjectTemplate, setUserProjects } from '@root-gipro/modules/userProjects/store/actions'
import { UserProjectsType } from '@root-gipro/modules/userProjects/models/interfaces/user-project.actions'
import { push } from 'react-router-redux'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { getCurrentPositionUnc } from '@root-gipro/utils/helpers/common'
import store from '@root-gipro/store'
import {
	IUnc,
	IUserProject,
	IRequestProject,
	IAllRequestProject,
	IUncTableRequest,
	IUncCell,
	ICheckedErrors,
	IUncAddBuffer,
} from '@root-gipro/modules/userProjects/models/interfaces/user-projects.model'
import produce from 'immer'
import { setActiveUncs } from '@root-gipro/modules/userProjects/store/actions'
import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'

//Сага загрузки/обновления проекта
function* fetchUserProject(action: { type: typeof UserProjectsType.FETCH_USER_PROJECT; id: string }) {
	const project: IUserProject = yield call(fetchData, `/user-projects/${action.id}`, (res: any) => res.userProject)

	if (!!project) {
		yield put({ type: UserProjectsType.CHOOSE_PROJECT, project })
		yield put({ type: UserProjectsType.SET_ORIGINAL_OBJ, project })
	}
}

//Сага сохраненеия проекта
function* saveUserProject({
	body,
	isNew,
	id,
}: {
	type: typeof UserProjectsType.SAVE_USER_PROJECT
	body: IUserProject
	isNew: boolean
	id: ReactText
}) {
	let copy: IUserProject = Object.assign({}, body)

	delete copy.sumUncCost
	delete copy.userId
	delete copy.sumUncCostWithNds
	delete copy.totalInvestmentUncOffer
	delete copy.t7Id

	let project: IRequestProject

	if (!(copy.code && copy.name && copy.years)) {
		yield put(
			showNotify({
				type: 'warning',
				message: 'Не заполнены обязательные поля',
			})
		)
		return
	}

	try {
		if (isNew) {
			project = yield call(fetchData, '/user-projects', (res: any) => res, 'POST', JSON.stringify(copy))
		} else {
			project = yield call(fetchData, `/user-projects/${id}`, (res: any) => res, 'PATCH', JSON.stringify(copy))
		}

		if (project && project.userProject) {
			const userProject: IUserProject = project.userProject

			yield put({ type: UserProjectsType.CHOOSE_PROJECT, project: userProject })
			yield put({ type: UserProjectsType.SET_ORIGINAL_OBJ, project: userProject })

			if (isNew) {
				yield put(
					showNotify({
						type: 'success',
						message: `Создан новый проект ${userProject.code}`,
					})
				)

				yield put(push(`/user-projects/project/${userProject.id}/parameters`))
			} else {
				yield put(
					showNotify({
						type: 'success',
						message: `Проект ${userProject.code} успешно сохранен`,
					})
				)
			}
		}
	} catch (error) {
		console.log(error)
	}
}

function* deleteUserProject({
	id,
	name,
}: {
	type: typeof UserProjectsType.DELETE_USER_PROJECT
	name: string
	id: string
}) {
	const res: Omit<IRequestProject, 'userProject'> = yield call(
		fetchData,
		`/user-projects/${id}`,
		(res: any) => res,
		'DELETE'
	)

	if (res.status === 'success') {
		yield put(
			showNotify({
				type: 'success',
				message: `Проект ${name} успешно удален!`,
			})
		)
		yield put(createProjectTemplate())
		yield put(push(`/user-projects`))
	}
}
function* uncEdit(params: { type: typeof UserProjectsType.UNC_POSITION_EDIT; params: any }) {
	// DroppableProps
	const { uncs } = store.getState().userProjects
	const userProjectId = store.getState().userProjects.projectObj.id
	const position = getCurrentPositionUnc(uncs, params)
	const table = params.params.destination.droppableId
	const id = params.params.draggableId
	try {
		yield call(fetchData, `/user-uncs/${id}`, (res: any) => res.userUnc, 'PATCH', JSON.stringify({ position, table }))

		yield put({ type: 'FETCH_UNCS', id: userProjectId })
	} catch (error) {
		console.log(error)
	}
}

function* addUnc({
	tableNum,
	uncCellId,
	count,
}: {
	type: typeof UserProjectsType.ADD_UNC
	tableNum: ReactText
	uncCellId: ReactText
	count: number
	addBuffer: boolean
}) {
	try {
		const project = store.getState().userProjects.projectObj

		if (project.code === '') throw new Error('Project not defined')

		const selectedElem = store.getState().userProjects.selectedElem
		let body: any = {
			tableNum,
			uncCellId,
			count,
		}
		if (project.regionIds && project.regionIds.length > 0) body.regionId = project.regionIds[0]
		if (selectedElem && selectedElem.code.match('С')) body.tableNum = 8
		const data: IUserProject = yield call(
			fetchData,
			`/user-projects/${project.id}/user-uncs`,
			(res: any) => res.userUnc,
			'POST',
			JSON.stringify(body)
		)

		if (data) {
			const newElem: unknown = yield call(
				fetchData,
				`/user-uncs/${data.id}?with=uncMain,uncCell,regionFactor`,
				(res: any) => res.userUnc
			)

			let userProjects = store.getState().userProjects
			const buffer = produce(userProjects, draft => {
				draft.uncAddBuffer.push(newElem as IUncAddBuffer)
			})

			yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: buffer.uncAddBuffer })
		}
	} catch (error) {
		console.log(error)
		if (error.message === 'Project not defined')
			yield put(
				showNotify({
					type: 'error',
					message: `Проект необходимо сохранить перед добавлением расценок!`,
				})
			)
	}
}
function* createUncB1({
	uncMainId,
	regionId,
	interpolated,
	tableNum,
	count,
}: {
	type: typeof UserProjectsType.CREATE_UNC_B1
	uncMainId: number
	regionId: ReactText
	interpolated: number
	tableNum: ReactText
	count: number
}) {
	try {
		const project = store.getState().userProjects.projectObj

		if (project.code === '') throw new Error('Project not defined')

		let body = {
			tableNum,
			uncMainId,
			regionId,
			interpolated,
			uncCellId: null,
		}

		const data: IUnc = yield call(
			fetchData,
			`/user-projects/${project.id}/user-uncs`,
			(res: any) => res.userUnc,
			'POST',
			JSON.stringify(body)
		)

		if (data) {
			const uncElem: IUnc = yield call(
				fetchData,
				`/user-uncs/${data.id}`,
				(res: any) => res.userUnc,
				'PATCH',
				JSON.stringify({ count })
			)

			const newElem: IUnc = yield call(
				fetchData,
				`/user-uncs/${data.id}?with=uncMain,uncCell,regionFactor`,
				(res: any) => res.userUnc
			)

			if (uncElem && newElem) {
				let clone = store.getState().userProjects.uncs.slice()
				clone.push(newElem)
				clone.sort((a, b) => a.tableNum - b.tableNum)
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone })
				yield put({ type: 'FETCH_USER_PROJECT', id: uncElem.userProjectId })
			}
		}
	} catch (error) {
		console.log(error)
		if (error.message === 'Project not defined')
			yield put(
				showNotify({
					type: 'error',
					message: `Проект необходимо сохранить перед добавлением расценок!`,
				})
			)
	}
}

function* createSpecialUnc({
	uncMainId,
	regionId,
	interpolated,
	tableNum,
}: {
	type: typeof UserProjectsType.CREATE_SPECIAL_UNC
	uncMainId: ReactText
	regionId: ReactText
	interpolated: { [key: string]: ReactText }
	tableNum: ReactText
}) {
	try {
		const project = store.getState().userProjects.projectObj

		if (project.code === '') throw new Error('Project not defined')

		let body: any = {
			tableNum,
			uncMainId,
			regionId,
			interpolated,
		}
		body.uncCellId = null
		const data: IUnc = yield call(
			fetchData,
			`/user-projects/${project.id}/user-uncs`,
			(res: any) => res.userUnc,
			'POST',
			JSON.stringify(body)
		)

		if (data) {
			const newElem: IUnc = yield call(
				fetchData,
				`/user-uncs/${data.id}?with=uncMain,uncCell,regionFactor`,
				(res: any) => res.userUnc
			)
			const userProjects = store.getState().userProjects
			const buffer = produce(userProjects, draft => {
				draft.uncAddBuffer.push(newElem as IUncAddBuffer)
			})
			yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: buffer.uncAddBuffer })
		}
	} catch (error) {
		console.log(error)
		if (error.message === 'Project not defined')
			yield put(
				showNotify({
					type: 'error',
					message: `Проект необходимо сохранить перед добавлением расценок!`,
				})
			)
	}
}

//Удаление унца
function* deleteUnc({ id, inBuffer }: { type: typeof UserProjectsType.DELETE_UNC; id: ReactText; inBuffer?: boolean }) {
	try {
		yield put({ type: 'FETCH_REQUEST' })
		const res: Omit<IRequestProject, 'userProject'> = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res,
			'DELETE'
		)

		if (res.status === 'success') {
			let projectId
			if (inBuffer) {
				const userProjects = store.getState().userProjects
				let ind = userProjects.uncAddBuffer.findIndex(elem => elem.id === id)
				projectId = userProjects.uncAddBuffer[ind].userProjectId
				const buffer = produce(userProjects, draft => {
					draft.uncAddBuffer.splice(
						draft.uncAddBuffer.findIndex(item => item.id === id),
						1
					)
				})
				yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: buffer.uncAddBuffer })
			} else {
				const userProjects = store.getState().userProjects
				const uncs = store.getState().userProjects.uncs
				const ind = uncs.findIndex((elem: any) => elem.id === id)
				projectId = uncs[ind].userProjectId
				const clone = produce(userProjects, draft => {
					draft.uncs.splice(
						draft.uncs.findIndex(item => item.id === id),
						1
					)
				})
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
			}

			yield put({ type: 'FETCH_USER_PROJECT', id: projectId })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки расценок
// function* fetchCostTable() {
// 	try {
// 		yield put({ type: 'SET_IN_LOADING', inLoading: true })
// 		let url = createUrlForPrice(store)
//
// 		const res = yield call(fetchData, url, res => res)
// 		const investmentPrograms = res.uncs
//
// 		yield put({ type: 'SET_COST_SEARCH', investmentPrograms })
// 		yield put({ type: 'SET_COST_PAGI_COUNTER', pagiCounter: 0 })
// 		yield put({
// 			type: 'SET_COUNTERS',
// 			resultsCnt: res.resultsCnt,
// 			projectsCnt: res.projectsCnt,
// 		})
// 		yield put({ type: 'SET_IN_LOADING', inLoading: false })
// 	} catch (error) {
// 		console.log(error)
// 	}
// }

//Сага добавления расценок в таблицу
// function* appendPricesSearch({ counter }) {
// 	try {
// 		let investmentPrograms = store.getState().costTable.investmentPrograms.slice()
// 		let url = createUrlForPrice(store, counter)
//
// 		const data = yield call(fetchData, url, res => res)
// 		investmentPrograms = investmentPrograms.concat(data.uncs)
//
// 		yield put({ type: 'SET_COST_SEARCH', investmentPrograms })
// 		if (
// 			(data.resultsCnt || data.resultsCnt === 0) &&
// 			(data.projectsCnt || data.projectsCnt === 0)
// 		) {
// 			yield put({
// 				type: 'SET_COUNTERS',
// 				resultsCnt: data.resultsCnt,
// 				projectsCnt: data.projectsCnt,
// 			})
// 		}
// 	} catch (error) {
// 		console.log(error)
// 	}
// }

// //Сага загрузки данных для таблицы в раскравыющейся строке
// function* fetchMiniTable({ id }) {
// 	try {
// 		const uncs = yield call(fetchData, `/projects/${id}/uncs?with=uncCell,uncMain,uncParameter`, res => res.uncs)
// 		if (uncs) {
// 			yield put({ type: 'SET_MINI_TABLE', uncs })
// 		}
// 	} catch (error) {
// 		console.log(error)
// 	}
// }

function* changeRegionFactor({ id, uncTableId, regionId }: any) {
	// try {
	// 	const region = yield call(
	// 		fetchData,
	// 		`/region-factors/search?uncTableId=${uncTableId}&regionId=${regionId}`,
	// 		(res: any) => res.regionFactor
	// 	)
	// 	const res = yield call(fetchData, `/uncs/${id}`, (res: any) => res.unc, 'PATCH', JSON.stringify({ regionId }))
	// 	let uncTable = store.getState().formTwenty.uncTable,
	// 		ind = searchByKey(uncTable, 'id', id)
	// 	let clone = uncTable.slice()
	// 	res.regionFactor = region
	// 	res.uncTable = clone[ind].uncTable
	// 	clone.splice(ind, 1, res)
	// 	yield put({ type: 'UPDATE_UNC_TABLE', uncTable: clone })
	// } catch (error) {
	// 	console.log(error)
	// }
}

function* changeFormTwentyName({ name }: any) {
	// try {
	// 	const id = store.getState().formTwenty.id
	// 	yield call(fetchData, `/projects/${id}`, res => res, 'PATCH', JSON.stringify({ name }))
	// } catch (error) {
	// 	console.log(error)
	// }
}

//Сага загрузки проектов
function* getMyProjects() {
	try {
		const projects: Omit<IAllRequestProject, 'userProject'> = yield call(fetchData, '/user-projects', (res: any) => res)

		if (projects) {
			yield put({
				type: 'SET_USER_PROJECTS',
				projects: projects.userProjects,
				userProjectsCnt: projects.userProjectsCnt,
				totalInvestmentUncOfferSum: projects.totalInvestmentUncOfferSum,
			})
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки таблицы унцов проекта
function* fetchUncs({ id }: { type: typeof UserProjectsType.FETCH_UNCS; id: string }) {
	try {
		const uncs: IUnc = yield call(
			fetchData,
			`/user-projects/${id}/user-uncs?with=uncMain,uncCell,regionFactor`,
			(res: any) => res.userUncs
		)
		if (uncs) {
			yield put({ type: 'SET_PROJECT_UNCS', uncs })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки таблицы унцов для добавления в проект
function* fetchUncTableData({ id }: { type: typeof UserProjectsType.FETCH_UNC_TABLE_DATA; id: number }) {
	try {
		const uncTable: IUncTableRequest = yield call(
			fetchData,
			`/unc-mains/${id}?with=uncTable`,
			(res: any) => res.uncMain
		)

		if (uncTable) {
			yield put({ type: 'SET_UNC_TABLE_DATA', uncTableData: uncTable.uncTable })
		}
	} catch (error) {
		console.log(error)
	}
}
// Сага изменения комментария расценки
function* changeUncComment({
	id,
	comment,
}: {
	type: typeof UserProjectsType.CHANGE_UNC_COMMENT
	id: ReactText
	comment: string
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ comment })
		)
		if (uncElem) {
			const userProjects = store.getState().userProjects
			const ind = userProjects.uncs.findIndex(elem => elem.id === id)
			const indActiveUncs = userProjects.activeUncs.findIndex(elem => elem.id === id)
			const cloneUnc = produce(userProjects, draft => {
				draft.uncs[ind].comment = uncElem.comment
			})
			const cloneActiveUncs = produce(userProjects, draft => {
				draft.activeUncs[indActiveUncs].comment = uncElem.comment
			})
			yield put(setActiveUncs([cloneActiveUncs.activeUncs[indActiveUncs]]))
			yield put({ type: 'SET_PROJECT_UNCS', uncs: cloneUnc.uncs })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага изменения количества единиц расценки
function* changeUncCounter({
	id,
	count,
	inBuffer,
}: {
	type: typeof UserProjectsType.CHANGE_UNC_COUNTER
	id: ReactText
	count: string
	inBuffer?: boolean | undefined
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ count })
		)

		if (uncElem) {
			const userProjects = store.getState().userProjects

			if (inBuffer) {
				const ind = userProjects.uncAddBuffer.findIndex(elem => elem.id === id)
				const copyBuffer = produce(userProjects, draft => {
					if (draft.uncAddBuffer[ind]) draft.uncAddBuffer[ind] = Object.assign(draft.uncAddBuffer[ind], uncElem)
				})
				yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: copyBuffer.uncAddBuffer })
			} else {
				const ind = userProjects.uncs.findIndex((elem: any) => elem.id === uncElem.id)
				const clone = produce(userProjects, draft => {
					if (ind !== -1) {
						draft.uncs[ind].count = uncElem.count
						draft.uncs[ind].totalCost = uncElem.totalCost
					}
				})
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
				yield put({ type: 'FETCH_USER_PROJECT', id: uncElem.userProjectId })
			}
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага изменения региона расценки
function* changeUncRegion({
	id,
	regionId,
}: {
	type: typeof UserProjectsType.CHANGE_UNC_REGION
	id: ReactText
	regionId: ReactText
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}?with=regionFactor`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ regionId })
		)
		if (uncElem) {
			const userProjects = store.getState().userProjects
			const ind = userProjects.uncs.findIndex((elem: any) => elem.id === uncElem.id)
			const clone = produce(userProjects, draft => {
				draft.uncs[ind] = Object.assign(draft.uncs[ind], uncElem)
			})
			yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
			yield put({ type: 'FETCH_USER_PROJECT', id: uncElem.userProjectId })
		}
	} catch (error) {
		console.log(error)
	}
}
//Сага изменения напряжения расценки
function* changeUncVoltage({
	id,
	voltage,
	inBuffer,
}: {
	type: typeof UserProjectsType.CHANGE_UNC_VOLTAGE
	id: string
	voltage: number
	inBuffer?: boolean
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ voltage })
		)
		if (uncElem) {
			const userProjects = store.getState().userProjects

			if (inBuffer) {
				const ind = userProjects.uncAddBuffer.findIndex(elem => elem.id === id)
				const copyBuffer = produce(userProjects, draft => {
					draft.uncAddBuffer[ind] = Object.assign(draft.uncAddBuffer[ind], uncElem)
				})

				yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: copyBuffer.uncAddBuffer })
			} else {
				const ind = userProjects.uncs.findIndex((elem: any) => elem.id === uncElem.id)
				const clone = produce(userProjects, draft => {
					draft.uncs[ind] = Object.assign(draft.uncs[ind], uncElem)
				})
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
			}
		}
	} catch (error) {
		console.log(error)
	}
}

function* selectVoltage({
	uncCellId,
	uncId,
}: {
	type: typeof UserProjectsType.SELECT_VOLTAGE
	uncCellId: ReactText
	uncId: ReactText
}) {
	try {
		const voltage: IUncCell = yield call(
			fetchData,
			`/uncCells/${uncCellId}?with=voltageValues`,
			(res: any) => res.uncCell
		)

		yield put({
			type: 'SET_VOLTAGE',
			uncId,
			targetVoltageValues: voltage.voltageValues,
		})

		yield put({ type: 'SET_VOLTAGE_ID', uncId, targetVoltageId: uncId })
	} catch (error) {
		console.log(error)
	}
}


function* setUncCellsFullInfo({
	uncCellId,
}: {
	type: typeof UserProjectsType.SELECT_VOLTAGE
	uncCellId: ReactText
}) {
	try {
		const data: IUncCell = yield call(
			fetchData,
			`/uncCells/${uncCellId}?with=voltageValues,uncTable`,
			(res: any) => res.uncCell
		)
		yield put({
			type: 'SET_UNC_CODES_FILTER',
			uncCodes: data
		})

	} catch (error) {
		console.log(error)
	}
}
function* updateDeflators({
	start,
	end,
}: {
	type: typeof UserProjectsType.UPDATE_DEFLATORS
	start: ReactText
	end: ReactText
}) {
	try {
		const deflators: { [key: string]: string } = yield call(
			fetchData,
			`/info/deflators?year.min=${start}&year.max=${end}`,
			(res: any) => res.deflators
		)

		if (deflators) {
			let originalObj: any = Object.assign({}, store.getState().userProjects.originalObj),
				editObj = Object.assign({}, store.getState().userProjects.projectObj)
			let years: any = {}
			for (let i = +start; i <= +end; i++) {
				years[i] = {
					offer: null,
					deflator: null,
				}
				if (originalObj[i]) {
					years[i].offer = originalObj[i].offer
					years[i].deflator = originalObj[i].deflator
				} else {
					years[i].offer = null
					years[i].deflator = deflators[i]
				}
			}
			editObj.years = years
			yield put({ type: 'CHOOSE_PROJECT', project: editObj })
		}
	} catch (error) {
		console.log(error)
	}
}

function* getCheckedErrors({
	id,
	sectionIdsArr,
}: {
	type: typeof UserProjectsType.GET_CHECKED_ERRORS
	id: ReactText | undefined
	sectionIdsArr: number[]
}) {
	try {
		const errors: ICheckedErrors = yield call(
			fetchData,
			`/user-projects/${id}/rule-error?with=messages`,
			(res: any) => res.userRuleError,
			'POST',
			JSON.stringify({ sectionIds: sectionIdsArr })
		)

		if (errors) {
			yield put({
				type: 'SET_PROJECT_ERRORS',
				errors: errors.messages,
			})
		} else {
			yield put({ type: 'SET_PROJECT_ERRORS', errors: '0' })
		}

		yield put({ type: 'SHOW_PROJECT_ERRORS', show: true })
	} catch (error) {
		console.log(error)
	}
}

function* downloadRulesCheck() {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/rules`, {
			method: 'GET',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(res => res.blob())
			.then(blob => {
				let url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = 'Правила проверки.xlsx'
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}

function* uploaderRulesCheck(file: any) {
	try {
		const target = file.file[0]
		const fileTypes = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
		if (!fileTypes.includes(target.type)) {
			yield put(showNotify({ type: 'warning', message: `Неверный формат файла! Допустимый формат - *.xlsx` }))
			return
		}

		let data = new FormData()
		data.append('file', target, target.name)

		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/rules`, {
			method: 'POST',
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(response => response.json())
			.then(success => {
				if (success.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: 'Ошибка загрузки файла, проверьте файл или попробуйте позже.',
						})
					)
				} else {
					store.dispatch(
						showNotify({
							type: 'success',
							message: 'Файл успешно загружен!',
						})
					)
				}
			})
			.catch(error => {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
			})
	} catch (error) {
		yield put(
			showNotify({
				type: 'error',
				message: 'Ошибка загрузки файла, попробуйте позже.',
			})
		)
	}
}

function* downloadProject({
	id,
	name,
	version,
}: {
	type: typeof UserProjectsType.DOWNLOAD_PROJECT
	id: ReactText
	name: string
	version: string
}) {
	var url = ''
	switch (version) {
		case 'base':
			// Старая Форма20 с одним проектом
			url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/f20`
			break
		case 'new':
			// Новая Форма20 с одним проектом
			url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/newf20`
			break
		case 'all':
			// Новая Форма20 со всеми проектами
			url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/forma20`
			break
		default:
			url = ''
	}

	try {
		yield fetch(url, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => res.blob())
			.then(blob => {
				let url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `${name}.xlsx`
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}

function* copyProject({
	userProjectId,
	code,
}: {
	type: typeof UserProjectsType.COPY_PROJECT
	userProjectId: ReactText
	code: ReactText
}) {
	try {
		const project: IRequestProject = yield call(
			fetchData,
			`/user-projects`,
			(res: any) => res,
			'POST',
			JSON.stringify({ userProjectId, code })
		)

		if (project.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: `Создан новый проект ${code}`,
				})
			)
		}
	} catch (error) {
		console.log(error)
	}
}

function* getTplUnnormalizedСosts({
	id,
}: {
	type: typeof UserProjectsType.GET_TEMPLATE_UNNORMOLIZED_COSTS
	id: ReactText
}) {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/t7-tpl`, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => res.blob())
			.then(blob => {
				let url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `Шаблон ненормируемых затрат.xlsx`
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}

function* getUnnormalizedСosts({ id }: { type: typeof UserProjectsType.GET_UNNORMOLIZED_COSTS; id: ReactText }) {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/t7`, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => res.blob())
			.then(blob => {
				let url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `Шаблон ненормируемых затрат.xlsx`
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}
function* uploaderUnnormalizedСosts({
	file,
	id,
}: {
	type: typeof UserProjectsType.UPLOAD_UNNORMOLIZED_COSTS
	file: any
	id: ReactText
}) {
	try {
		const fileTypes = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
		if (!fileTypes.includes(file[0].type)) {
			yield put(
				showNotify({
					type: 'warning',
					message: 'Неверный формат файла! Допустимый формат - *.xlsx',
				})
			)
			return
		}

		let data = new FormData()
		data.append('file', file[0], file[0].name)

		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/t7`, {
			method: 'POST',
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(response => response.json())
			.then(success => {
				if (success.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: 'Ошибка загрузки файла, попробуйте позже.',
						})
					)
				} else {
					store.dispatch(
						showNotify({
							type: 'success',
							message: 'Файл успешно загружен!',
						})
					)
				}
			})
			.catch(error => {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
			})
	} catch (error) {
		console.log(error)
	}
}

function* deleteUnnormalizedСosts({ id }: { type: typeof UserProjectsType.DELETE_UNNORMOLIZED_COSTS; id: ReactText }) {
	try {
		const res: IRequestProject = yield call(fetchData, `/user-projects/${id}/t7`, (res: any) => res, 'DELETE')

		if (res.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: 'Файл успешно удален!',
				})
			)
		}
	} catch (error) {
		console.log(error)
	}
}
function* sortUserProjects({
	order,
	sortName,
}: {
	type: typeof UserProjectsType.SORT_USER_PROJECTS
	sortName: string
	order?: boolean
}) {
	try {
		yield put(setUserProjects([], 0, 0))
		let params = { order: (order ? '' : '-') + sortName }

		const { userProjects } = store.getState().filter
		const object = excludeData(userProjects.showFilterState)

		let intermediateValue = {
			...object,
		}

		if (userProjects.showFilterState || !!Object.keys(intermediateValue).length) {
			params = {
				...params,
				...intermediateValue,
			}
		}

		const res = yield call(userProjectsApi, params)

		if (res && res.status === 'success') {
			yield put({
				type: 'SET_USER_PROJECTS',
				projects: res.userProjects,
				userProjectsCnt: res.userProjectsCnt,
				totalInvestmentUncOfferSum: res.totalInvestmentUncOfferSum,
			})
		}
	} catch (error) {
		console.log(error)
	} finally {
	}
}

export default function* watchUserProject() {
	yield takeEvery(UserProjectsType.DELETE_UNNORMOLIZED_COSTS, deleteUnnormalizedСosts)
	yield takeEvery(UserProjectsType.UPLOAD_UNNORMOLIZED_COSTS, uploaderUnnormalizedСosts)
	yield takeEvery(UserProjectsType.GET_UNNORMOLIZED_COSTS, getUnnormalizedСosts)
	yield takeEvery(UserProjectsType.SORT_USER_PROJECTS, sortUserProjects)
	yield takeEvery(UserProjectsType.COPY_PROJECT, copyProject)
	yield takeEvery(UserProjectsType.GET_TEMPLATE_UNNORMOLIZED_COSTS, getTplUnnormalizedСosts)
	yield takeEvery(UserProjectsType.DOWNLOAD_PROJECT, downloadProject)
	yield takeEvery(UserProjectsType.UPLOADER_RULES_CHECK, uploaderRulesCheck)
	yield takeEvery(UserProjectsType.DOWNLOAD_RULES_CHECK, downloadRulesCheck)
	yield takeEvery(UserProjectsType.GET_CHECKED_ERRORS, getCheckedErrors)
	yield takeLatest(UserProjectsType.UPDATE_DEFLATORS, updateDeflators)
	yield takeEvery(UserProjectsType.SELECT_VOLTAGE, selectVoltage)
	yield takeEvery(UserProjectsType.SET_UNC_CELL_INFO, setUncCellsFullInfo)
	yield takeEvery(UserProjectsType.CHANGE_UNC_VOLTAGE, changeUncVoltage)
	yield takeEvery(UserProjectsType.CHANGE_UNC_REGION, changeUncRegion)
	yield takeEvery(UserProjectsType.CHANGE_UNC_COUNTER, changeUncCounter)
	yield takeEvery(UserProjectsType.CHANGE_UNC_COMMENT, changeUncComment)
	yield takeLatest(UserProjectsType.FETCH_UNC_TABLE_DATA, fetchUncTableData)
	yield takeLatest(UserProjectsType.FETCH_UNCS, fetchUncs)
	yield takeLatest(UserProjectsType.GET_USER_PROJECTS, getMyProjects)
	// 	yield takeEvery(UserProjectsType.APPEND_UNC_SEARCH, appendPricesSearch)
	// 	yield takeLatest(UserProjectsType.FETCH_MINI_TABLE, fetchMiniTable)
	// yield takeLatest(UserProjectsType.FETCH_COST_TABLE, fetchCostTable)
	yield takeLatest(UserProjectsType.CHANGE_FORM_TWENTY_NAME, changeFormTwentyName)
	yield takeLatest(UserProjectsType.CHANGE_REGION_FACTOR, changeRegionFactor)
	yield takeEvery(UserProjectsType.DELETE_UNC, deleteUnc)
	// yield takeLatest(UserProjectsType.FETCH_MINI_TABLE, fetchMiniTable)
	yield takeEvery(UserProjectsType.CREATE_SPECIAL_UNC, createSpecialUnc)
	yield takeEvery(UserProjectsType.CREATE_UNC_B1, createUncB1)
	yield takeLatest(UserProjectsType.ADD_UNC, addUnc)
	yield takeLatest(UserProjectsType.UNC_POSITION_EDIT, uncEdit)
	yield takeLatest(UserProjectsType.SAVE_USER_PROJECT, saveUserProject)
	yield takeLatest(UserProjectsType.FETCH_USER_PROJECT, fetchUserProject)
	yield takeLatest(UserProjectsType.DELETE_USER_PROJECT, deleteUserProject)
}
