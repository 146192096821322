import { Action } from 'redux'
import { IRefreshList } from '@root-gipro/modules/filter/store/actions'
import { IFilterRates, IFilterState, IParams } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { isShowFilterCloud } from '../../store/actions'
import { IConfigures, IPresetFilter, IUncCell } from '@root-gipro/store/interfaces'

export enum FilterActions {
	SET_CALLBACKS = 'FILTER/SET_CALLBACKS',
	REFRESH_FILTER_STATE = 'REFRESH_FILTER_STATE',
	SHOW_FILTER_RESULT = 'FILTER/SHOW_FILTER_RESULT',
	SET_FILTER_SORT_PARAMS = 'FILTER/SET_FILTER_SORT_PARAMS',
	SET_FILTER_RESULT_COUNT = 'FILTER/SET_FILTER_RESULT_COUNT',
	RESET_FILTER_STATE = 'FILTER/RESET_FILTER_STATE',
	SET_INIT_FILTER_STATE = 'FILTER/SET_INIT_FILTER_STATE',
	SET_INIT_RESULT_FILTER_STATE = 'FILTER/SET_INIT_RESULT_FILTER_STATE',
	SET_FILTER_STATE = 'FILTER/SET_FILTER_STATE',
	REPLACE_FILTER_PARAMS = 'FILTER/REPLACE_FILTER_PARAMS',

	FILTER_SET_RESULT = 'FILTER_SET_RESULT',
	REFRESH_FILTER_COMMON_PROJECT_STATE = 'FILTER/REFRESH_FILTER_COMMON_PROJECT_STATE',
	FILTER_REQUEST = 'FILTER_REQUEST',
	SET_UNC_CODES_FILTER = 'SET_UNC_CODES_FILTER',
	DELETE_UNC_FROM_FILTER = 'DELETE_UNC_FROM_FILTER',
	CLEAN_UNC_FROM_FILTER = 'CLEAN_UNC_FROM_FILTER',
	SET_FILTER_COMMON_PROJECTS_RATES = 'SET_FILTER_COMMON_PROJECTS_RATES',
	RESET_FILTER_COMMON_PROJECTS_RATES = 'RESET_FILTER_COMMON_PROJECTS_RATES',
	IS_LOADING_COUNT_RESULT = 'IS_LOADING_COUNT_RESULT',
	IS_SHOW_FILTER_CLOUD = 'IS_SHOW_FILTER_CLOUD',
	SET_FILTER_MODAL_STATE = 'SET_FILTER_MODAL_STATE',
	SET_FILTER_CONFIGURE_MODAL_STATE = 'SET_FILTER_CONFIGURE_MODAL_STATE',
	SET_FILTER_CONFIGURES = 'SET_FILTER_CONFIGURES',
	SET_ACTUAL_FILTER = 'SET_ACTUAL_FILTER',
	SET_OPEN_PRESET_FILTER_MODAL = 'SET_OPEN_PRESET_FILTER_MODAL',
	SET_PRESET_FILTER_STATE = 'SET_PESET_FILTER_STATE',
	GET_PRESET_FILTERS = 'GET_PRESET_FILTERS',
	SAVE_PRESET_FILTER = 'SAVE_PRESET_FILTER',
	EDIT_PRESET_FILTER = 'EDIT_PRESET_FILTER',
	DELETE_PRESET_FILTERS = 'DELETE_PRESET_FILTERS',
	SET_PRESET_FILTERS = 'SET_PRESET_FILTERS',
	IS_SELECTED_PRESET_FILTER = 'IS_SELECTED_PRESET_FILTER',
}

interface SetCallbacks {
	type: FilterActions.SET_CALLBACKS
	getInitList: () => void
	showFilterResult: (params: IParams) => void
	refreshList: (projects: IRefreshList[]) => Action
	toggleLoadingList: (loading: boolean) => Action
}

interface FiltersRequest {
	type: FilterActions.FILTER_REQUEST
	url: string
}

interface FilterSetResult {
	type: FilterActions.FILTER_SET_RESULT
}

interface SetFilterSortParams {
	type: FilterActions.SET_FILTER_SORT_PARAMS
	params: any
	url: string
}

interface RefreshFilterState {
	type: FilterActions.REFRESH_FILTER_STATE
	fieldName: string
	value: string
	url: string
	convertValue: (fieldName: string, value: string) => { fieldName: string; value: string }
}
interface ShowFilterResult {
	type: FilterActions.SHOW_FILTER_RESULT
	url: string
}

interface ResetFilterState {
	type: FilterActions.RESET_FILTER_STATE
	url: string
}

interface SetInitFilterState {
	revertFilterValue: any
	type: FilterActions.SET_INIT_FILTER_STATE
	state: any
}

interface SetFilterState {
	type: FilterActions.SET_FILTER_STATE
	state: IFilterState
}

interface SetFilterResultCount {
	type: FilterActions.SET_FILTER_RESULT_COUNT
	count: number
	url: string
}

interface SetFilterCommonProjectsRates {
	type: FilterActions.SET_FILTER_COMMON_PROJECTS_RATES
	data: IFilterRates
}

interface ResetFilterCommonProjectsRates {
	type: FilterActions.RESET_FILTER_COMMON_PROJECTS_RATES
}
interface IsLoadingCountResult {
	type: FilterActions.IS_LOADING_COUNT_RESULT
	isLoading: boolean
}
interface IsShowFilterCloud {
	type: FilterActions.IS_SHOW_FILTER_CLOUD
	isShowFilterCloud: boolean
}

interface setUncCodesFilter {
	type: FilterActions.SET_UNC_CODES_FILTER
	uncCodes: IUncCell
}
interface deleteUncFromFilter {
	type: FilterActions.DELETE_UNC_FROM_FILTER
	unc: IUncCell
}
interface cleanUncFromFilter {
	type: FilterActions.CLEAN_UNC_FROM_FILTER
}
interface setFilterUncsModalState {
	type: FilterActions.SET_FILTER_MODAL_STATE
	isFilterUncsModalState: boolean
}
interface setFilterConfigureModalState {
	type: FilterActions.SET_FILTER_CONFIGURE_MODAL_STATE
	isFilterConfigureModalState: boolean
}
interface setFilterConfigures {
	type: FilterActions.SET_FILTER_CONFIGURES
	configures: IConfigures
}
interface setActualFilter {
	type: FilterActions.SET_ACTUAL_FILTER
	isActualFilter: number
}

interface setOpenPresetFilterModal {
	type: FilterActions.SET_OPEN_PRESET_FILTER_MODAL
	isOpenPresetFilterModal: boolean
	mode: 'save' | 'edit' | null
}

interface setPresetFilterState {
	type: FilterActions.SET_PRESET_FILTER_STATE
	dataPresetFilter: IPresetFilter[]
}

interface GetPresetFilters {
	type: FilterActions.GET_PRESET_FILTERS
}

interface SavePresetFilters {
	type: FilterActions.SAVE_PRESET_FILTER
	name: string
	filters: any
}
interface EditPresetFilters {
	type: FilterActions.EDIT_PRESET_FILTER
	id: string
	name: string
	filters: any
}
interface DeletePresetFilters {
	type: FilterActions.DELETE_PRESET_FILTERS
	id: string
}
interface SetPresetFilters {
	type: FilterActions.SET_PRESET_FILTERS
	url: string
	filters: any
}
interface IsSelectedPresetFilter {
	type: FilterActions.IS_SELECTED_PRESET_FILTER
	selected: boolean
}

export type FilterTypes =
	| ShowFilterResult
	| RefreshFilterState
	| SetFilterSortParams
	| SetCallbacks
	| FiltersRequest
	| FilterSetResult
	| ResetFilterState
	| SetInitFilterState
	| SetFilterState
	| SetFilterResultCount
	| SetFilterCommonProjectsRates
	| ResetFilterCommonProjectsRates
	| IsLoadingCountResult
	| IsShowFilterCloud
	| setUncCodesFilter
	| deleteUncFromFilter
	| setFilterUncsModalState
	| setActualFilter
	| cleanUncFromFilter
	| setOpenPresetFilterModal
	| setPresetFilterState
	| setFilterConfigureModalState
	| setFilterConfigures
	| GetPresetFilters
	| SavePresetFilters
	| EditPresetFilters
	| DeletePresetFilters
	| SetPresetFilters
	| IsSelectedPresetFilter
