import React, { ChangeEvent, ReactText, useEffect, useState } from 'react'
import { Checkbox, fade, ListItemText, Radio, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { isEqual } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import '@root-gipro/shared/FormElements/Select/Select.scss'

const useStyles = makeStyles(theme => ({
	option: {
		position: 'relative',
		// height: 32,
		'& .MuiAutocomplete-inputRoot': {
			paddingTop: 4,
			paddingBottom: 4,
		},
	},
}))

const useStylesReddit = makeStyles(theme => ({
	root: {
		border: '1px solid #e2e2e1',
		overflow: 'hidden',
		borderRadius: 4,
		paddingRight: 0,
		backgroundColor: '#fff',
		paddingTop: 7,
		height: 'auto',
		paddingBottom: 7,
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:hover': {
			backgroundColor: '#fff',
		},
		'&$focused': {
			backgroundColor: '#fff',
			borderColor: theme.palette.primary.main,
		},
		'& .MuiIconButton-root': {
			padding: '6px',
		},
	},
	focused: {},
}))
export interface Props {
	value: string[]
	setValue(fieldValue: string, value: ReactText | string[]): void
	name?: string
	fieldName: string
	items: string[]
	placeholder?: string
	isDisable?: boolean
	withCheckbox?: {
		setChecked(value: boolean): void
		checked: boolean
		label: string
		disabled?: boolean
		checkboxChange?(): void
	}
	isMultiple?: boolean
	convertValue?(value: string): string
	disableCloseOnSelect?: boolean
}

const Select: React.FC<Props> = ({
	value,
	setValue,
	name,
	placeholder,
	isDisable,
	fieldName,
	items,
	withCheckbox,
	isMultiple,
	convertValue,
	disableCloseOnSelect = true,
}) => {
	const [open, setOpen] = useState<boolean>(false)
	const [selectValue, setSelectValue] = useState<string | string[] | null>([])
	const classesAutocomplete = useStyles()
	const classes = useStylesReddit()
	const handleSelect = (event: ChangeEvent<{ value?: string | undefined }>, value: string | string[] | null) => {
		setValue(fieldName, value as ReactText | string[])
		if (!isMultiple) setSelectValue(event.target.value ? event.target.value : value || [])
	}

	useEffect(() => {
		if (!isEqual(value, selectValue) && isMultiple) {
			setSelectValue(value || [])
		}
	}, [value])

	return (
		<>
			{name ? <h4 className='universal-select-title'>{name}</h4> : null}
			{/* {placeholder && <label className='universal-select-label'>{placeholder}</label>} */}

			<Autocomplete
				className={classesAutocomplete.option + ' top-inherit'}
				multiple={isMultiple}
				options={items}
				value={selectValue}
				disableCloseOnSelect={disableCloseOnSelect}
				clearOnEscape
				clearText='Очистить'
				openText='Открыть'
				disabled={isDisable || withCheckbox?.checked}
				includeInputInList={false}
				closeIcon={<span className='close-icon' />}
				popupIcon={<span className='dropdown-icon' />}
				noOptionsText='Нет значений'
				closeText='Закрыть'
				openOnFocus
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				onInputChange={(event: ChangeEvent<{ value?: string | undefined }>, value: string, reason: string) => {
					if (!isMultiple) {
						setValue(fieldName, value)
					}
				}}
				onChange={handleSelect}
				onBlur={(event: ChangeEvent<{ value?: string | undefined }>) => handleSelect(event, value)}
				filterOptions={(options, { inputValue }) => {
					if (isMultiple) {
						return Array.from(new Set([...items, ...options])).filter(value => {
							return value && value.toLowerCase().includes(inputValue?.toLowerCase())
						})
					} else {
						return Array.from(new Set([...items, ...options]))
							.filter(value => {
								return value && value.toLowerCase().includes(inputValue.toLowerCase())
							})
							.slice(0, 5)
					}
				}}
				// renderTags={(value: string[]) => (
				// 	<div className={isMultiple && open ? 'select-filter__multiple transparent-text' : 'select-filter__multiple'}>
				// 		{value.join(', ')}
				// 	</div>
				// )}
				limitTags={1}
				renderOption={(date, { selected }) =>
					isMultiple ? (
						<>
							{/* {selected ? <span className='checked-icon checked-icon-position' /> : null} */}
							{date}
						</>
					) : (
						<>
							<Radio checked={selected} style={{ marginRight: 8 }} color='primary' />
							<span>{date}</span>
						</>
					)
				}
				renderInput={params => {
					return (
						<TextField
							{...params}
							type='text'
							variant='filled'
							margin='dense'
							size='small'
							hiddenLabel
							InputProps={{
								...params.InputProps,
								classes: { root: classes.root, focused: classes.focused },
								disableUnderline: true,
							}}
							inputProps={{ ...params.inputProps }}
							placeholder={placeholder}
						/>
					)
				}}
			/>
			{withCheckbox ? (
				<div className='aside-filter__checkbox'>
					<Checkbox checked={withCheckbox?.checked} onChange={() => withCheckbox?.setChecked(!withCheckbox?.checked)} />
					<ListItemText primary={withCheckbox?.label} />
				</div>
			) : null}
		</>
	)
}
export default Select
