import React, { ChangeEvent, useEffect, useState } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { FilterItemInputProps } from '@root-gipro/shared/Filter/models/filter-item-input.props'
import TextField from '@material-ui/core/TextField'

const FilterItemTextArea: React.FC<FilterItemInputProps> = ({
	value,
	setValue,
	inputName,
	labelName,
	iconName,
	disabled,
	handleBlur,
	clearState,
}) => {
	const [inputValue, setInputValue] = useState(value || '')

	useEffect(() => {
		if (value !== inputValue) {
			setInputValue(value || '')
		}
	}, [value])

	const resetInput = () => {
		if (clearState) clearState()
		setValue(inputName, '')
	}

	const onBlur = () => {
		if (handleBlur) {
			setValue(inputName, inputValue.trim())
			handleBlur()
		}
	}

	const handleInput = (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)

	// inputs styles
    const useStylesReddit = makeStyles(theme => ({
        root: {
          border: '1px solid #e2e2e1',
          overflow: 'hidden',
          borderRadius: 4,
          height: 'auto',
          backgroundColor: '#fff',
          padding: '12px 0 0 0 !important',
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          '&:hover': {
            backgroundColor: '#fff',
          },
          '&$focused': {
            backgroundColor: '#fff',
            borderColor: theme.palette.primary.main,
          },
          '& .MuiFilledInput-marginDense': {
            padding: '0 !important'
          },
          '& .MuiInputBase-input': {
            padding: '10px 9px 6px !important'

          }
        },
        focused: {},
      }))
	const classes = useStylesReddit()

	return (
	    <div className='aside-filter__input'>
      <TextField
        value={inputValue}
        onChange={handleInput}
        onBlur={onBlur}
        name={inputName}
        label={labelName}
        margin='dense'
        variant='filled'
        InputProps={{
          classes,
          disableUnderline: true,
          endAdornment: inputValue && (
            <IconButton type='button' onClick={resetInput} className='reset-icon'>
              <span className='close-icon' />
            </IconButton>
          ),
        }}
        color='primary'
        fullWidth
        disabled={disabled}
        multiline
        rowsMax={15}  
      />
    </div>
	)
}

export default FilterItemTextArea
