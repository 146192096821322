import React, { FC, useEffect, useMemo, useState } from 'react'
import './configureFilterStyles.scss'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { useDispatch } from 'react-redux'
import { setFilterConfigureModalState, setFilterConfigures } from '../store/actions'
import { IUncTableData } from '@root-gipro/store/interfaces'
import ConfigureSelect from './ConfigureSelect'

interface Props {
	uncTableData: IUncTableData | null
	setSelectedCells: React.Dispatch<React.SetStateAction<{ x: number; y: number }[]>>
}

const ConfigureFilter: FC<Props> = ({ uncTableData, setSelectedCells }) => {
	const [configValues, setConfigValues] = useState<any>()
	const [configs, setConfigs] = useState<any>([])

	const [bodyData, setBodyData] = useState<any>()
	const [headerData, setHeaderData] = useState<any>()
	const [isHardTable, setIsHardTable] = useState(false)

	const dispatch = useDispatch()
	const handleCloseConfigure = () => {
		dispatch(setFilterConfigureModalState(false))
	}

	const setConfig = () => {
		if (filteredBodyData && Array.isArray(filteredBodyData)) {
			const res = filteredBodyData
			if (isHardTable) {
				const idCell = configValues[0]
				const result = res[0].filter((item: any) => {
					if (item.uncCellId) {
						if (item.x === idCell.z) {
							return item
						}
					} else {
						return item
					}
				})

				setSelectedCells([...result, { x: idCell.z, y: res[0][0].y, id: result[result.length - 1]?.uncCellId }])
				dispatch(setFilterConfigures({id: result[result.length - 1]?.uncCellId,x: result[0]?.x}))

			} else {
				const resPosition = res[0][res[0]?.length - 1]
				setSelectedCells([...res[0], { x: resPosition?.x, y: resPosition?.y, id: resPosition?.uncCellId }])
				dispatch(setFilterConfigures({id: resPosition?.uncCellId,x: resPosition?.x}))
			}
			dispatch(setFilterConfigureModalState(false))
		}
	}
	const getCorrectBodyData = (lastElements: any) => {
		if (Array.isArray(uncTableData?.body)) {
			const arrBody = [...(uncTableData?.body as any)]
			const index = lastElements.length
			const corrLast = lastElements.map((item: any) => {
				const newItem = { ...item, z: item.x }
				return newItem
			})
			corrLast.map((item: any) => (item.x = corrLast[0].z))

			return [
				...arrBody.slice(0, index),
				...corrLast.map((item: any) => {
					item.x = corrLast[0].z
					return [item]
				}),
				...arrBody.slice(index),
			]
		}
	}
	useEffect(() => {
		if (uncTableData && Array.isArray(uncTableData?.header[0])) {
			if (uncTableData?.header.length > 1) {
				setIsHardTable(true)
				const data = [...(uncTableData?.header[0] as any)]
				// Удаляю элементы с объектами, содержащими наименование "Норматив цены"
				const filteredData = data.filter(item => {
					if (item?.name && item?.name !== 'Номер расценок') {
						return !item.name.includes('Норматив цены')
					}
				})

				// Беру последние два элемента из отфильтрованного массива
				const [lastheader, lastElements] = uncTableData?.header.slice(-2).map((item: any) => Object.values(item))
				const lastTwoElements = lastheader.filter((item: any) => item.class === undefined)

				// Объединяю последние два элемента в один массив
				const mergedArray = filteredData.concat(...lastTwoElements)

				setHeaderData(mergedArray)
				setBodyData(getCorrectBodyData(lastElements))
			} else {
				setIsHardTable(false)

				setHeaderData(
					[...(uncTableData.header[0] as any)].filter(item => {
						if (item?.name && item?.name !== 'Номер расценок') {
							return !item.name.includes('Норматив цены')
						}
					})
				)
				setBodyData([...(uncTableData?.body as any)])
			}
		}
	}, [])

	const filteredBodyData = useMemo(() => {
		if (!configs.length) return bodyData
		const res = bodyData?.filter((item: any) => {
			const configures = configs.map((item: any) => Object.values(item)[0])
			const results = configures.every((it: any) => !!item.find((itm: any) => itm.name == it))
			return results
			// }
		})
		return res
	}, [configValues, bodyData, configs])

	const checkDissabled = () => {
		if (configs?.length === headerData?.length || (configs?.length === headerData?.length - 1 && configValues)) {
			return false
		}
		return true
	}
	return (
		<>
			<div className='configure_zone'></div>
			<div className='configure'>
				<div className='content'>
					<div className='content_header'>Укажите значения</div>
					<div className='configure_block'>
						{headerData?.map((item: any, ind: number) => {
							return (
								<ConfigureSelect
									bodyData={bodyData}
									setConfigValues={setConfigValues}
									headItem={item}
									uncTableData={uncTableData}
									headPosition={ind}
									headLength={headerData.length - 1}
									filteredBodyData={headerData.length - 1 == ind && isHardTable ? bodyData : filteredBodyData}
									isHardTable={isHardTable}
									setConfigs={setConfigs}
								/>
							)
						})}
					</div>
					<div className='configure_footer'>
						<div className='configure_footer_buttons'>
							<PrimaryBtn variant='contained' className='fullWidth' disabled={checkDissabled()} onClick={setConfig}>
								Готово
							</PrimaryBtn>
							<SecondaryBtn variant='contained' onClick={handleCloseConfigure} className='fullWidth'>
								Закрыть
							</SecondaryBtn>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ConfigureFilter
