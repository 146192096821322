import '@root-gipro/modules/commonProjects/styles/RangePriceFilter.scss'
import { convertFilterValue, revertFilterValue } from '@root-gipro/modules/filter/helpers/filter-state-convert'
import {
	cleanUncCodeFromFilter,
	filtersRequest,
	isLoadingCountResult,
	isShowFilterCloud,
	refreshFilterState,
	resetFilterState,
	setCallbacks,
	setFilterSortParams,
	setInitFilterState,
	setPresetFilters,
	showFilterResult as showFilterResultHandler,
} from '@root-gipro/modules/filter/store/actions'
import { Filter } from '@root-gipro/modules/purchases/containers'
import '@root-gipro/modules/purchases/styles/PurchasesContainer.scss'
import LoaderContainer from '@root-gipro/shared/LoaderContainer/LoaderContainer'
import { IState } from '@root-gipro/store/interfaces/state'
import { decode, encode } from '@root-gipro/utils/helpers/queryString'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { setPurchasesPaginationCounter } from '../purchases/store/actions'
import { getCommonProjects, loadingCommonProjects, setCommonProjects } from '../commonProjects/store/actions'
import { commonProjectsApi } from '@root-gipro/store/api'

const FilterContainer: FC = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { countPublicInfoFetches } = useSelector((state: IState) => state.public)
	const publicInfo = useSelector((state: IState) => state.public)
	const { purchasesType } = useSelector((state: IState) => state.purchases)
	const uncInfoCommonProjects = useSelector((state: IState) => state.commonProjects.uncInfo)
	const uncInfoPurchases = useSelector((state: IState) => state.purchases.uncInfo)

	const url: string = location.pathname.slice(1)

	const { getInitList, isSelectPresetFilter } = useSelector((state: IState) => state.filter)
	const { filter } = useSelector((state: IState) => state)

	const currentFilter =
		url === 'purchases'
			? filter.purchases
			: url === 'common-projects'
			? filter.commonProjects
			: url === 'favorites-common-projects'
			? filter.commonProjectsFavorite
			: url === 'favorites-purchases'
			? filter.purchasesFavorite
			: url === 'user-projects'
			? filter.userProjects
			: filter.userProjects

	let { resultFilterState, showFilterState, countResult } = useSelector((state: IState) => currentFilter)

	let rgStatuses = useSelector((state: IState) => state.public.rgStatuses)
	rgStatuses = [
		...rgStatuses,
		{
			id: '',
			title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
		},
	]
	const [isFirstVisit, setIsFirstVisit] = useState(false)

	useEffect(() => {
		// Проверяем наличие флага в sessionStorage
		const hasVisited = sessionStorage.getItem('hasVisited')

		if (!hasVisited) {
			// Если флаг отсутствует, это первое посещение или обновление страницы
			sessionStorage.setItem('hasVisited', 'true')
			setIsFirstVisit(true)
		} else {
			setIsFirstVisit(false)
		}
	}, [])

	useEffect(() => {
		if (countPublicInfoFetches >= 13) {
			dispatch(isShowFilterCloud(true))
		}
		if (url === 'common-projects' && localStorage.getItem('urlSearch')) {
			const params =  decode(localStorage.getItem('urlSearch'))
		setTimeout(()=>{
			dispatch(setPresetFilters('common-projects', params))
		},0)
			isFirstVisit && showResult()
		}
	}, [])

	const refresh = (fieldName: string, value: string) => {
		dispatch(isLoadingCountResult(true))
		dispatch(
			refreshFilterState(
				fieldName,
				value,
				url,
				convertFilterValue(
					publicInfo.regions,
					publicInfo.groups,
					publicInfo.company,
					publicInfo.uncThs,
					publicInfo.uncThDescriptions,
					rgStatuses,
					publicInfo.uncMains
				)
			)
		)
	}

	useEffect(() => {
		history.push({
			pathname: location.pathname,
			search: encode(showFilterState),
		})
	}, [isSelectPresetFilter])

	const showResult = useCallback(() => {
		if (url === 'common-projects') {
			localStorage.setItem('urlSearch', encode(showFilterState))
			history.push({
				pathname: location.pathname,
				search: encode(showFilterState),
			})
			dispatch(setCallbacks(getCommonProjects, commonProjectsApi, setCommonProjects, loadingCommonProjects))
		}

		if (!!Number(countResult)) dispatch(showFilterResultHandler(url, true))
		dispatch(setPurchasesPaginationCounter(5))
	}, [countResult])

	const reset = () => {
		url === 'common-projects' && localStorage.removeItem('urlSearch')
		dispatch(getInitList!())
		dispatch(resetFilterState(url))
		dispatch(filtersRequest(url))
		dispatch(cleanUncCodeFromFilter())
		history.push({
			pathname: location.pathname,
			search: '',
		})
	}
	return (
		<div className='aside-filter-container'>
			<LoaderContainer isLoading={!getInitList}>
				{getInitList && (
					<Filter
						showFilterResult={showResult}
						refreshFilterState={refresh}
						resetFilter={reset}
						filterCountResult={countResult}
						filterState={resultFilterState}
						purchasesType={purchasesType}
						minMaxValues={publicInfo.minMax}
						uncInfo={url === 'purchases' ? uncInfoPurchases : uncInfoCommonProjects}
						{...publicInfo}
						rgStatuses={rgStatuses}
					/>
				)}
			</LoaderContainer>
			{/* <div className='aside-filter-copyright'>
				Copyright 2022 ©<br />
				ООО «Мастерская цифровых решений»
			</div> */}
		</div>
	)
}

export default FilterContainer
